//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import notifications from '../store/lazy/notifications';

export default {
    computed: {
        notifications() {
            return this.$store.state.ui.notifications;
        },
    },

    created() {
        this.$store.registerModule(['ui', 'notifications'], notifications);
    },
};
